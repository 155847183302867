<template>
  <v-dialog v-model="open" persistent width="400">
    <v-card>
      <v-card-title>庫存報廢</v-card-title>
      <v-card-text>
        <div class="mb-3">
          <v-text-field
            label="商品名稱"
            outlined
            dense
            disabled
            hide-details
            :value="stock.name"
          ></v-text-field>
        </div>
        <div class="mb-3">
          <v-text-field
            v-model.number="stock.amount"
            label="庫存數量"
            outlined
            dense
            clearable
            hide-details
            disabled
          ></v-text-field>
        </div>
        <div class="mb-3">
          <v-text-field
            v-model.number="amount"
            type="number"
            label="報廢數量"
            outlined
            dense
            clearable
            hide-details
          ></v-text-field>
        </div>
        <div class="mb-3">
          <v-textarea
            v-model="comment"
            placeholder="請備註報廢原因"
            label="備註"
            rows="2"
            row-height="25"
            auto-grow
            outlined
            hide-details
          ></v-textarea>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn text class="bt-text-large" color="error" @click="open = false">
          取消
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text class="bt-text-large" color="primary" @click="confirm">
          確定
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DialogStockScrapped",
  props: ["value", "stock", "branch"],
  data: () => ({
    amount: null,
    comment: "",
  }),
  computed: {
    open: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  created() {},
  methods: {
    async confirm() {
      try {
        if (this.amount > this.stock.amount) {
          this.$toast.error("報廢數量不可大於庫存數量");
          return;
        }

        if (this.amount < 0) {
          this.$toast.error("報廢數量不可小於 0");
          return;
        }

        await this.axios.post(`/transaction`, {
          type: "報廢",
          from: this.branch,
          productId: this.stock.productId,
          amount: this.amount,
          ...(this.comment && { comment: this.comment }),
        });

        this.$toast.success("報廢完成");
        this.$emit("load");
        this.open = false;
      } catch (error) {
        console.log(error);
        this.$toast.error("報廢失敗");
      }
    },
  },
};
</script>

<style></style>
