<script>
import dayjs from "dayjs";
import * as XLSX from "xlsx";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);

export default {
  data: () => ({
    ignoreKeys: [
      "imageUrl",
      "imageURL",
      "productId",
      "_id",
      "store",
      "exchangeBranch",
      "expired",
      "isAvailable",
      "isOnline",
      "branch",
    ],
    translatedHeader: {
      branch: "分店",
      category: "商品類別",
      exchangeBranch: "兌換分店",
      expired: "有效日期",
      imageUrl: "圖片網址",
      isAvailable: "上架",
      isOnline: "線上兌換",
      name: "商品名稱",
      productId: "商品id",
      store: "商店",
      type: "類型",
      _id: "id",
      points: "點數",
      price: "售價",
      amount: "數量",
      cost: "總額",
      regulatedAt: "最新校正時間",
    },
  }),
  computed: {
    // monthlyInventory() {
    //   const groupedItems = _.groupBy(this.inventory, "stock._id");
    //   const currentMonth = dayjs().month() + 1;
    //   const filteredItems = _.mapValues(groupedItems, (group) => {
    //     const currentMonthItems = group.filter(
    //       (item) => dayjs(item.created).month() + 1 === currentMonth
    //     );
    //     if (currentMonthItems.length > 0) {
    //       return _.maxBy(currentMonthItems, (item) => dayjs(item.created));
    //     } else {
    //       return null;
    //     }
    //   });
    //   // console.log(_.keyBy(_.values(filteredItems), "stock._id"));
    //   return _.keyBy(_.values(filteredItems), "stock._id");
    // },
  },
  methods: {
    // async getInventory() {
    //   const { data } = await this.axios.get(`/inventory`);
    //   this.inventory = data;
    // },
    async update(item, key) {
      this.$vloading.show();
      try {
        await this.axios.put(`/stock/update-${key}/${item._id}`, {
          [key]: item[key],
        });
        this.$toast.success("更新完成！");
        await this.load();
      } catch (error) {
        this.$toast.error("更新失敗！");
      }
      this.close();
      this.$vloading.hide();
    },
    isNearExpired(date) {
      if (!date) return false;
      const today = dayjs();
      const twoMonthsLater = today.add(2, "month");

      if (dayjs(date).isSameOrBefore(today, "day")) {
        return true;
      }
      return dayjs(date).isBetween(today, twoMonthsLater);
    },
  },
};
</script>
