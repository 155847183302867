<template>
  <v-dialog v-model="open" persistent width="400">
    <v-card>
      <v-card-title>盤點</v-card-title>
      <v-card-text>
        <div class="mb-3">
          <v-text-field
            label="商品名稱"
            outlined
            dense
            disabled
            hide-details
            :value="stock.name"
          ></v-text-field>
        </div>
        <div class="mb-3">
          <v-text-field
            v-model.number="stock.amount"
            label="原庫存數量"
            outlined
            dense
            clearable
            hide-details
            disabled
          ></v-text-field>
        </div>
        <div class="mb-3" v-if="inventory">
          <v-text-field
            v-model.number="inventory.amount"
            label="盤點數量"
            outlined
            dense
            clearable
            hide-details
            disabled
          ></v-text-field>
        </div>
        <div class="mb-3">
          <v-text-field
            v-model.number="amount"
            label="更新盤點數量"
            outlined
            dense
            clearable
            hide-details
          ></v-text-field>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn text class="bt-text-large" color="error" @click="open = false">
          取消
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text class="bt-text-large" color="primary" @click="confirm">
          確定
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "DialogStockRegulate",
  props: ["value", "stock", "branch", "inventory"],
  data: () => ({
    amount: null,
  }),
  computed: {
    open: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  created() {},
  methods: {
    async confirm() {
      try {
        if (this.amount < 0 || this.amount === null) {
          this.$toast.error("盤點數量不可小於 0");
          return;
        }

        const check = await this.$dialog.confirm({
          text: `<p class="headline">是否確認盤點數量為
          <span class="red--text darken-4--text">${this.amount}</span>
          ？ 將一併校正庫存。</p>`,
        });
        if (!check) return;

        this.$vloading.show();
        const today = dayjs().format("YYYY-MM-DD");
        if (this.inventory) {
          await this.axios.put(`/inventory/${this.inventory._id}`, {
            amount: this.amount,
          });
        } else {
          await this.axios.post(`/inventory`, {
            date: today,
            branch: this.branch,
            stock: this.stock,
            amount: this.amount,
          });
        }
        await this.axios.post(`/transaction`, {
          type: "校正",
          from: this.branch,
          productId: this.stock.productId,
          amount: this.amount - this.stock.amount,
          comment: "盤點校正",
        });

        this.$toast.success("盤點完成");
        this.$emit("load");
        this.open = false;
        this.$vloading.hide();
      } catch (error) {
        console.log(error);
        this.$toast.error(error.response?.data?.message || "儲存失敗");
        this.$vloading.hide();
      }
    },
  },
};
</script>

<style></style>
